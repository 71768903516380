import { Icon as Iconify } from '@iconify/react';
import { useState, useEffect } from "react";
import BackLogo from "./assets/images/logo.svg";
import './App.css';


const serverProd = "https://api.codespure.com";
const serverDev = "http://localhost:4000"

const Icon = ({ name, size, color }) => {
  return <Iconify
    style={{
      color
    }}
    icon={name}
    fontSize={size}
  />;
};

const numberLine = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}

function App() {
  const [userData, setUserData] = useState();
  const { appLogo } = {
    "appLogo": "https://images.371digital.com/codesPureMiniLogo.svg"
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const userName = query.get('userName');
    console.log('ComingUserName', userName)
    fetch(`${serverProd}/api/profile/getProfile?userName=${userName}`).then((res) => res.json()).then((res) => {
      if (res.code === 200) setUserData(res.data);
    });
  }, []);


  const Link = ({
    iconColor = "white",
    withCircle = false,
    marginLeft = 16,
    iconSize = 36,
    size = 24,
    icon,
    url,
  }) => {
    return <div className='linkContainer'>
      <div style={withCircle ? {
        backgroundColor: "white",
        boxSizing: 'border-box',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 20,
        display: "flex",
      } : null}>
        <Icon
          color={iconColor}
          name={icon}
          size={iconSize}
        />
      </div>
      <div className='linkText' style={{ fontSize: size, marginLeft: marginLeft }}>
        {url}
      </div>
    </div>
  }

  const Count = ({
    icon,
    count,
    title,
  }) => {
    return <div className='countContainer'>
      <div className='countHeader'>
        <div className='countIconContainer'>
          <Icon
            name={icon}
            size={"30px"}
            color="white"
          />
        </div>
        <div className='countText'>
          {count}
        </div>
      </div>
      <div className='countTitle'>
        {title}
      </div>
    </div>
  };

  return (
    <div className="App">
      {Object.keys(userData?.user || {}).length && <div id="userCardItem" className="userCard">
        <img
          src={BackLogo}
          style={{
            height: "100vh",
            position: "absolute",
            right: 0,
            top: -10,
            zIndex: 1
          }}
        />
        <div className='rowContainer'>
          <div className='textsContainer'>
            <div className='fullName'>
              {userData.user.fullName}
            </div>
            <div className='userName'>
              @{userData.user.userName}
            </div>
            {userData.user.company?.length && <Link
              icon='heroicons:building-office-2-solid'
              url={userData.user.company}
              marginLeft={8}
            />}
            {userData.user.twitterUserName?.length && <Link
              url={`@${userData.user.twitterUserName}`}
              icon='ant-design:twitter-circle-filled'
              iconColor="#009BDD"
              withCircle={true}
              size={20}
              iconSize="38px"
            />}
            {userData.user.blog?.length && <Link
              url={userData.user.blog}
              iconColor="#009BDD"
              withCircle={true}
              icon='jam:world'
              size={20}
              iconSize="38px"
            />}
          </div>
          <div>
            <img
              src={userData.user.image}
              className="userImage"
            />
          </div>
        </div>
        <div className='rowContainer bottom'>
          <div className='rowContainer'>
            <Count
              count={userData.codes.length}
              icon="charm:git-branch"
              title={"Total Code"}
            />
            <Count
              count={userData.codes.map(({ likeCount }) => likeCount).reduce((prevValue, currentValue) => prevValue + currentValue, 0)}
              icon="ant-design:like-filled"
              title={"Total Likes"}
            />
          </div>
          <img
            src={appLogo}
            className="appLogo"
          />
        </div>
      </div>}
    </div>
  );
}

export default App;
